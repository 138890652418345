<template>
  <v-card>
    <v-toolbar color="titleBar" dark flat dense>
      <v-toolbar-title>
        {{ $t("order new mobile phone subscription") }}
      </v-toolbar-title>
      <v-spacer />
      <v-tooltip
        top
        v-if="
          transferPhoneNumber &&
          ((isWho && $config.mobileTransferFormWho) ||
            $config.mobileTransferForm)
        "
      >
        <template v-slot:activator="{ on }">
          <v-btn
            v-on="on"
            :href="
              isWho ? $config.mobileTransferFormWho : $config.mobileTransferForm
            "
            icon
            download
          >
            <v-icon>mdi-file-edit-outline</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("POA document template") }}</span>
      </v-tooltip>
    </v-toolbar>
    <v-card-text>
      <v-stepper v-model="step" alt-labels>
        <v-stepper-header>
          <v-stepper-step :complete="step > 1" step="1" class="text-no-wrap">
            {{ $t("subscription") }}
          </v-stepper-step>
          <v-divider />

          <v-stepper-step :complete="step > 2" step="2" class="text-no-wrap">
            {{ isWho ? $t("end customer account") : $t("customer account") }}
          </v-stepper-step>
          <v-divider />

          <v-stepper-step :complete="step > 3" step="3" class="text-no-wrap">
            {{ $t("identity check") }}
          </v-stepper-step>
          <v-divider />

          <v-stepper-step :complete="step > 4" step="4" class="text-no-wrap">
            {{ $t("transfer number") }}
            <small>{{ $t("optional") }}</small>
          </v-stepper-step>
          <v-divider />

          <v-stepper-step :complete="step > 5" step="5" class="text-no-wrap">
            {{ $t("options") }}
          </v-stepper-step>
          <v-divider />

          <v-stepper-step :complete="step > 6" step="6" class="text-no-wrap">
            {{ $t("delivery") }}
          </v-stepper-step>
          <v-divider />

          <v-stepper-step step="7" class="text-no-wrap">
            {{ $t("permit") }}
          </v-stepper-step>
        </v-stepper-header>

        <v-stepper-items>
          <validation-observer ref="obs">
            <!-- ----------------------- step 1 - subscription ----------------------- -->
            <v-stepper-content step="1">
              <validation-observer
                ref="obsStep1"
                v-slot="{ errors, invalid, validated }"
              >
                <v-card outlined color="transparent">
                  <v-card-text>
                    <v-card class="px-6" outlined>
                      <v-data-table
                        :headers="[
                          { value: 'name' },
                          { value: 'price' },
                          {
                            text: 'action',
                            align: 'end',
                            sortable: false,
                          },
                        ]"
                        :items="productCatalog"
                        :loading="loadingProductCatalog"
                        @click:row="selectProduct"
                        hide-default-footer
                        hide-default-header
                      >
                        <template v-slot:item.name="{ item }">
                          <div class="subtitle-1 my-4">
                            {{
                              item.gfo_variant
                                ? item.gfo_variant.subscription
                                : item.caption
                            }}
                          </div>
                          <div
                            v-if="item.gfo_variant && item.gfo_variant.text"
                            class="caption my-4 text-justify"
                            v-html="item.gfo_variant.text"
                          />
                          <div
                            v-if="
                              !isWho &&
                              item.gfo_variant &&
                              item.gfo_variant.terms
                            "
                            class="caption my-4 text-justify"
                            v-html="item.gfo_variant.terms"
                          />
                        </template>
                        <template v-slot:item.price="{ item }" v-if="!isWho">
                          <div class="monospace subtitle-1">
                            {{
                              item.gfo_variant &&
                              item.gfo_variant.price_rec_incl
                                ? formatMoney(
                                    item.gfo_variant.price_rec_incl,
                                    "CHF"
                                  )
                                : "-"
                            }}
                          </div>
                        </template>
                        <template v-slot:item.action="{ item }">
                          <v-icon right>mdi-chevron-right</v-icon>
                        </template>
                      </v-data-table>
                    </v-card>
                  </v-card-text>
                </v-card>
              </validation-observer>
            </v-stepper-content>

            <!-- ----------------------- step 2 - account ----------------------- -->
            <v-stepper-content step="2">
              <validation-observer
                ref="obsStep2"
                v-slot="{ errors, invalid, validated }"
              >
                <v-card outlined color="transparent">
                  <v-card-text>
                    <v-card
                      outlined
                      v-if="$store.getters.isSalesPartner"
                      class="mb-8"
                    >
                      <v-toolbar color="titleBar" dark flat dense>
                        <v-toolbar-title>
                          {{ $t("sales partner options") }}
                        </v-toolbar-title>
                        <v-spacer />
                        <v-dialog
                          v-model="createCustomerDialog"
                          width="80%"
                          scrollable
                        >
                          <template v-slot:activator="{ on }">
                            <v-tooltip top v-on="on">
                              <template v-slot:activator="{ on }">
                                <v-btn
                                  v-on="on"
                                  @click="createCustomerDialog = true"
                                  :disabled="
                                    loadingCustomers || createCustomerDialog
                                  "
                                  :loading="loadingCustomers"
                                  icon
                                >
                                  <v-icon>mdi-account-plus-outline</v-icon>
                                </v-btn>
                              </template>
                              <span>{{ $t("add customer") }}</span>
                            </v-tooltip>
                          </template>
                          <create-client-customer
                            @created="
                              createCustomerDialog = false;
                              readCustomers($event);
                            "
                            @canceled="createCustomerDialog = false"
                          />
                        </v-dialog>
                      </v-toolbar>
                      <v-card-text>
                        <v-row>
                          <v-col cols="6">
                            <validation-provider
                              vid="on_behalf_of"
                              :name="$t('on behalf of')"
                              v-slot="{ errors, valid, dirty, classes }"
                            >
                              <v-autocomplete
                                v-model="onBehalfOf"
                                name="on_behalf_of"
                                :items="customers"
                                item-text="name"
                                item-value="number"
                                :label="$t('on behalf of')"
                                :hint="$t('order on behalf of your customer')"
                                clearable
                                persistent-hint
                                hide-selected
                                single-line
                              >
                                <template v-slot:selection="data">
                                  {{ data.item.name }} #{{ data.item.number }}
                                </template>
                                <template v-slot:item="data">
                                  #{{ data.item.number }} {{ data.item.name }}
                                </template>
                              </v-autocomplete>
                            </validation-provider>
                          </v-col>
                          <v-col cols="6">
                            <validation-provider
                              vid="on_behalf_order_type"
                              :name="$t('on behalf order type')"
                              v-slot="{ errors, valid, dirty, classes }"
                            >
                              <v-select
                                v-model="onBehalfOrderType"
                                :items="onBehalfOrderTypes"
                                :label="$t('on behalf order type')"
                                :hint="$t('onBehalfOrderTypeHint')"
                                clearable
                                single-line
                                persistent-hint
                              >
                              </v-select>
                            </validation-provider>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>

                    <v-row>
                      <v-col cols="12">
                        <validation-provider
                          v-if="isWho"
                          vid="account_data.corporate"
                          :name="
                            isWho
                              ? $t('end customer is corporate')
                              : $t('customer is corporate')
                          "
                          v-slot="{ errors, valid, dirty, classes }"
                        >
                          <v-switch
                            v-model="accountData.corporate"
                            name="account_data.corporate"
                            :label="
                              isWho
                                ? $t('end customer is corporate')
                                : $t('customer is corporate')
                            "
                            :error-messages="errors"
                            :class="classes"
                            :clearable="!isMobile"
                          />
                        </validation-provider>
                      </v-col>
                    </v-row>

                    <v-card outlined>
                      <v-toolbar color="titleBar" dark flat dense>
                        <v-toolbar-title>
                          {{
                            isWho
                              ? $t("end customer account data")
                              : $t("customer account data")
                          }}
                        </v-toolbar-title>
                        <v-spacer />
                      </v-toolbar>
                      <v-card-text>
                        <v-row v-if="accountData.corporate">
                          <v-col cols="12">
                            <validation-provider
                              vid="account_data.company"
                              :name="$t('company')"
                              rules="required|max:100"
                              v-slot="{ errors, valid, dirty, classes }"
                            >
                              <v-text-field
                                v-model="accountData.company"
                                name="account_data.company"
                                :label="$t('company')"
                                :error-messages="errors"
                                :success="dirty && valid"
                                :class="classes"
                                counter="100"
                                :clearable="!isMobile"
                              />
                            </validation-provider>
                          </v-col>
                        </v-row>
                        <v-row v-if="!accountData.corporate">
                          <v-col cols="6">
                            <validation-provider
                              vid="account_data.first_name"
                              :name="$t('first name')"
                              :rules="
                                (accountData.corporate ? '' : 'required|') +
                                'max:100'
                              "
                              v-slot="{ errors, valid, dirty, classes }"
                            >
                              <v-text-field
                                v-model="accountData.first_name"
                                name="account_data.first_name"
                                :label="$t('first name')"
                                :error-messages="errors"
                                :success="dirty && valid"
                                :class="classes"
                                counter="100"
                                :clearable="!isMobile"
                              />
                            </validation-provider>
                          </v-col>
                          <v-col cols="6">
                            <validation-provider
                              vid="account_data.last_name"
                              :name="$t('last name')"
                              :rules="
                                (accountData.corporate ? '' : 'required|') +
                                'max:100'
                              "
                              v-slot="{ errors, valid, dirty, classes }"
                            >
                              <v-text-field
                                v-model="accountData.last_name"
                                name="account_data.last_name"
                                :label="$t('last name')"
                                :error-messages="errors"
                                :success="dirty && valid"
                                :class="classes"
                                counter="100"
                                :clearable="!isMobile"
                              />
                            </validation-provider>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12">
                            <address-postal
                              v-model="accountData.address"
                              additional-max-length="255"
                              additional-hide
                              :zip-max-length="10"
                              zip-name="zip_code"
                              zip-rules="required"
                              :city-max-length="100"
                              city-rules="required"
                              :street-max-length="100"
                              street-rules="required"
                              :street-number-max-length="10"
                              street-number-rules="required"
                              name-prefix="account_data."
                            />
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>

                    <v-card class="px-6 mt-4" outlined>
                      <v-row v-if="false">
                        <v-col cols="6">
                          <validation-provider
                            vid="account_data.email"
                            :name="$t('email')"
                            rules="email:max:100"
                            v-slot="{ errors, valid, dirty, classes }"
                          >
                            <v-text-field
                              v-model="accountData.email"
                              name="account_data.email"
                              :label="$t('email')"
                              :error-messages="errors"
                              :success="dirty && valid"
                              :class="classes"
                              counter="100"
                              :clearable="!isMobile"
                            />
                          </validation-provider>
                        </v-col>
                        <v-col cols="6">
                          <validation-provider
                            vid="account_data.mobile"
                            :name="$t('mobile number')"
                            rules="phone:max:100"
                            v-slot="{ errors, valid, dirty, classes }"
                          >
                            <v-text-field
                              v-model="accountData.mobile"
                              name="account_data.mobile"
                              :label="$t('mobile number')"
                              :error-messages="errors"
                              :success="dirty && valid"
                              :class="classes"
                              counter="100"
                              :clearable="!isMobile"
                            />
                          </validation-provider>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="6" class="">
                          <language-select
                            v-model="accountData.language"
                            name="account_data.language"
                            rules=""
                            :label="$t('language')"
                            :codes="['de', 'en', 'fr', 'it', 'es', 'pt']"
                          />
                        </v-col>
                        <v-col cols="6" v-if="false">
                          <date-picker
                            v-model="accountData.date_of_birth"
                            name="account_data.date_of_birth"
                            :label="$t('date of birth')"
                            :clearable="!isMobile"
                            :allowedDates="allowedDateOfBirth"
                            :min="
                              $moment(new Date())
                                .subtract(100, 'years')
                                .format('YYYY-MM-DD')
                            "
                            :max="
                              $moment(new Date())
                                .subtract(18, 'years')
                                .format('YYYY-MM-DD')
                            "
                            active-picker="YEAR"
                          />
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="6" v-if="false">
                          <country-select
                            v-model="accountData.nationality"
                            name="account_data.nationality"
                            :label="$t('nationality')"
                          />
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn @click="step = 1" text>
                      {{ $t("back") }}
                    </v-btn>
                    <v-spacer />
                    <v-btn
                      color="primary"
                      @click="step = 3"
                      :disabled="invalid || loading"
                      text
                    >
                      {{ $t("continue") }}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </validation-observer>
            </v-stepper-content>

            <!-- ----------------------- step 3 - identity ----------------------- -->
            <v-stepper-content step="3">
              <validation-observer
                ref="obsStep3"
                v-slot="{ errors, invalid, validated }"
              >
                <v-card outlined color="transparent">
                  <v-card-text>
                    <v-card-subtitle>
                      <v-alert outlined prominent text type="info">
                        {{ isWho ? $t("identityMsgWho") : $t("identityMsg") }}
                        <template v-if="isCorporate">
                          <br /><br />
                          {{ $t("identityMsg2") }}
                        </template>
                      </v-alert>
                    </v-card-subtitle>
                    <v-card class="px-6" outlined v-if="isCorporate">
                      <v-row>
                        <v-col cols="6">
                          <validation-provider
                            vid="account_data.first_name"
                            :name="$t('first name')"
                            rules="required|max:100"
                            v-slot="{ errors, valid, dirty, classes }"
                          >
                            <v-text-field
                              v-model="accountData.first_name"
                              name="account_data.first_name"
                              :label="$t('first name')"
                              :error-messages="errors"
                              :success="dirty && valid"
                              :class="classes"
                              counter="100"
                              :clearable="!isMobile"
                            />
                          </validation-provider>
                        </v-col>
                        <v-col cols="6">
                          <validation-provider
                            vid="account_data.last_name"
                            :name="$t('last name')"
                            rules="required|max:100"
                            v-slot="{ errors, valid, dirty, classes }"
                          >
                            <v-text-field
                              v-model="accountData.last_name"
                              name="account_data.last_name"
                              :label="$t('last name')"
                              :error-messages="errors"
                              :success="dirty && valid"
                              :class="classes"
                              counter="100"
                              :clearable="!isMobile"
                            />
                          </validation-provider>
                        </v-col>
                      </v-row>
                    </v-card>
                    <v-row>
                      <v-col
                        cols="6"
                        v-if="$store.getters.isStaffAdmin || isWho"
                      >
                        <validation-provider
                          vid="identity_data.check_manual"
                          :name="$t('manual check')"
                          v-slot="{ errors, valid, dirty, classes }"
                        >
                          <v-switch
                            v-model="identityData.check_manual"
                            name="identity_data.check_manual"
                            :label="$t('manual check')"
                            :error-messages="errors"
                            :class="classes"
                            :color="!isWho ? 'staff' : ''"
                          >
                            <template v-slot:label>
                              <span
                                :class="{
                                  'staff--text': !isWho,
                                }"
                              >
                                {{ $t("manual check") }}
                              </span>
                            </template>
                          </v-switch>
                        </validation-provider>
                      </v-col>
                    </v-row>

                    <v-sheet
                      v-if="identityData.check_manual"
                      :class="!isWho ? 'mt-4' : ''"
                      outlined
                      :color="!isWho ? 'staff' : 'transparent'"
                      rounded
                    >
                      <v-card class="px-6" outlined>
                        <v-row align="baseline" justify="center">
                          <v-col cols="6">
                            <validation-provider
                              vid="account_data.date_of_birth"
                              :name="$t('date of birth')"
                              v-slot="{ errors, valid, dirty, classes }"
                            >
                              <date-picker
                                v-model="accountData.date_of_birth"
                                name="account_data.date_of_birth"
                                rules="required"
                                :label="$t('date of birth')"
                                :clearable="!isMobile"
                                :allowedDates="allowedDateOfBirth"
                                :min="
                                  $moment(new Date())
                                    .subtract(100, 'years')
                                    .format('YYYY-MM-DD')
                                "
                                :max="
                                  $moment(new Date())
                                    .subtract(18, 'years')
                                    .format('YYYY-MM-DD')
                                "
                                active-picker="YEAR"
                              />
                            </validation-provider>
                          </v-col>
                          <v-col cols="6">
                            <country-select
                              v-model="accountData.nationality"
                              name="account_data.nationality"
                              :label="$t('nationality')"
                              rules="required"
                            />
                          </v-col>
                        </v-row>
                        <v-row align="baseline" justify="center">
                          <v-col cols="6">
                            <validation-provider
                              vid="identity_data.check_type"
                              :name="$t('identity document type')"
                              rules="required"
                              v-slot="{ errors, valid, dirty, classes }"
                            >
                              <v-select
                                v-model="identityData.check_type"
                                name="identity_data.check_type"
                                :items="checkTypes"
                                item-text="text"
                                item-value="value"
                                :label="$t('identity document type')"
                                :error-messages="errors"
                                :success="dirty && valid"
                                :class="classes"
                                :clearable="!isMobile"
                                open-on-clear
                                required
                              />
                            </validation-provider>
                          </v-col>
                          <v-col cols="6">
                            <validation-provider
                              vid="identity_data.identity_number"
                              :name="$t('identity document number')"
                              rules="required|max:100"
                              v-slot="{ errors, valid, dirty, classes }"
                            >
                              <v-text-field
                                v-model="identityData.identity_number"
                                name="identity_data.identity_number"
                                :label="$t('identity document number')"
                                :error-messages="errors"
                                :success="dirty && valid"
                                :class="classes"
                                counter="100"
                                :clearable="!isMobile"
                              />
                            </validation-provider>
                          </v-col>
                        </v-row>
                        <v-row align="baseline">
                          <v-col cols="6">
                            <validation-provider
                              vid="identity_data.identity_document"
                              :name="$t('identity document')"
                              rules="required"
                              v-slot="{ errors, classes, dirty, valid }"
                            >
                              <v-file-input
                                v-model="identityDocument"
                                name="identity_data.identity_document"
                                :label="$t('identity document')"
                                :hint="$t('identityDocumentHint')"
                                :error-messages="errors"
                                :success="dirty && valid"
                                :class="classes"
                                :clearable="!isMobile"
                                show-size
                              />
                            </validation-provider>
                          </v-col>
                        </v-row>
                      </v-card>
                    </v-sheet>

                    <v-card class="px-6" outlined v-else>
                      <v-row align="baseline" justify="center">
                        <v-col cols="6">
                          <validation-provider
                            vid="identity_data.mfa_verification_type"
                            :name="$t('multi factor authentication type')"
                            rules="required"
                            v-slot="{ errors, valid, dirty, classes }"
                          >
                            <v-select
                              v-model="identityData.mfa_verification_type"
                              name="identity_data.mfa_verification_type"
                              :items="mfaVerificationTypes"
                              item-text="text"
                              item-value="value"
                              :label="$t('multi factor authentication type')"
                              :hint="$t('mfaTypeHint')"
                              :error-messages="errors"
                              :success="dirty && valid"
                              :class="classes"
                              :clearable="!isMobile"
                              open-on-clear
                              required
                              disabled
                            />
                          </validation-provider>
                        </v-col>
                        <v-col cols="6">
                          <validation-provider
                            vid="identity_data.mfa_target"
                            :name="
                              identityData.mfa_verification_type == 'EMAIL'
                                ? $t('authentication email address')
                                : identityData.mfa_verification_type == 'SMS'
                                ? $t('authentication SMS number')
                                : $t('authentication target')
                            "
                            :rules="
                              'required|' +
                              (identityData.mfa_verification_type == 'EMAIL'
                                ? 'email'
                                : identityData.mfa_verification_type == 'SMS'
                                ? 'phone'
                                : '')
                            "
                            v-slot="{ errors, valid, dirty, classes }"
                          >
                            <v-text-field
                              v-if="
                                identityData.mfa_verification_type == 'EMAIL'
                              "
                              v-model="identityData.mfa_target_email"
                              name="identity_data.mfa_target"
                              :label="$t('authentication email address')"
                              :error-messages="errors"
                              :success="dirty && valid"
                              :class="classes"
                              :clearable="!isMobile"
                            />
                            <v-text-field
                              v-else
                              v-model="identityData.mfa_target_sms"
                              name="identity_data.mfa_target"
                              :label="$t('authentication SMS number')"
                              :error-messages="errors"
                              :success="dirty && valid"
                              :class="classes"
                              :clearable="!isMobile"
                            />
                          </validation-provider>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn @click="step = 2" text>
                      {{ $t("back") }}
                    </v-btn>
                    <v-spacer />
                    <v-btn
                      color="primary"
                      @click="step = 4"
                      :disabled="invalid || loading"
                      text
                    >
                      {{ $t("continue") }}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </validation-observer>
            </v-stepper-content>

            <!-- ----------------------- step 4 - transfer ----------------------- -->
            <v-stepper-content step="4">
              <validation-observer
                ref="obsStep4"
                v-slot="{ errors, invalid, validated }"
              >
                <v-card class="mb-12" outlined color="transparent">
                  <v-card-text>
                    <v-row>
                      <v-col cols="12">
                        <validation-provider
                          vid="transfer_phone_number"
                          :name="$t('transfer phone number')"
                          v-slot="{ errors, valid, dirty, classes }"
                        >
                          <v-switch
                            v-model="transferPhoneNumber"
                            :label="
                              transferPhoneNumber
                                ? $t('Yes, I would like to port a number.')
                                : $t('No, I would not like to port a number.')
                            "
                            name="transfer_phone_number"
                            :error-messages="errors"
                            :class="classes"
                            @input="
                              wishMobileNumber = transferPhoneNumber
                                ? null
                                : wishMobileNumber
                            "
                          />
                        </validation-provider>
                      </v-col>
                    </v-row>

                    <v-card class="px-6" outlined v-if="transferPhoneNumber">
                      <v-row>
                        <v-col cols="16">
                          <validation-provider
                            vid="transfer_data.phone_number"
                            :name="$t('phone number')"
                            rules="required|phone"
                            v-slot="{ errors, valid, dirty, classes }"
                          >
                            <v-text-field
                              v-model="transferData.phone_number"
                              name="transfer_data.phone_number"
                              :label="$t('phone number')"
                              :error-messages="errors"
                              :success="dirty && valid"
                              :class="classes"
                              :clearable="!isMobile"
                              @change="
                                transferData.phone_number =
                                  dirty && valid
                                    ? formatPhoneNumber($event)
                                    : $event
                              "
                            />
                          </validation-provider>
                        </v-col>
                        <v-col cols="6">
                          <!--
                          <validation-provider
                            vid="transder_data.provider_account_id"
                            :name="$t('provider')"
                            v-slot="{ errors, valid, dirty, classes }"
                          >
                            <v-combobox
                              name="identity_data.provider_account_id"
                              :value="transferData.provider_account_value"
                              :search-input.sync="
                                transferData.provider_account_search
                              "
                              :items="providers"
                              item-text="caption"
                              item-value="id"
                              :filter="providerFilter"
                              :label="$t('provider')"
                              :error-messages="errors"
                              :success="dirty && valid"
                              :class="classes"
                              :loading="loadingProviders"
                              :clearable="!isMobile"
                              v-disabled-icon-focus
                              @input="providerInput"
                              return-object
                            />
                          </validation-provider>
                          -->
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="6">
                          <date-picker
                            v-model="transferData.transfer_date"
                            name="transfer_data.transfer_date"
                            :label="$t('transfer date')"
                            :hint="$t('transferDateHint')"
                            :allowedDates="allowedDatesTransfer"
                            :clearable="!isMobile"
                          />
                        </v-col>
                        <v-col cols="6">
                          <validation-provider
                            vid="transfer_data.transfer_time"
                            :name="$t('transfer time')"
                            rules="required"
                            v-slot="{ errors, classes, dirty, valid }"
                          >
                            <v-select
                              v-model="transferData.transfer_time"
                              name="transfer_data.transfer_time"
                              :label="$t('transfer time')"
                              :items="transferTimeSlots"
                              item-text="text"
                              item-value="value"
                              :error-messages="errors"
                              :success="dirty && valid"
                              :class="classes"
                              :clearable="!isMobile"
                            />
                          </validation-provider>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="6">
                          <validation-provider
                            vid="transfer_data.connection_type"
                            :name="$t('contract type')"
                            rules="required"
                            v-slot="{ errors, classes, dirty, valid }"
                          >
                            <v-select
                              v-model="transferData.connection_type"
                              name="transfer_data.connection_type"
                              :label="$t('contract type')"
                              :items="connectionTypes"
                              item-text="text"
                              item-value="value"
                              :error-messages="errors"
                              :success="dirty && valid"
                              :class="classes"
                              :clearable="!isMobile"
                            />
                          </validation-provider>
                        </v-col>
                        <v-col cols="6">
                          <validation-provider
                            vid="transfer_data.transfer_priority"
                            :name="$t('transfer priority')"
                            rules="required"
                            v-slot="{ errors, classes, dirty, valid }"
                          >
                            <v-select
                              v-model="transferData.transfer_priority"
                              name="transfer_data.transfer_priority"
                              :label="$t('transfer priority')"
                              :items="transferPriorities"
                              item-text="text"
                              item-value="value"
                              :error-messages="errors"
                              :success="dirty && valid"
                              :class="classes"
                              :clearable="!isMobile"
                            />
                          </validation-provider>
                        </v-col>
                      </v-row>
                      <!--
                      <v-row>
                         <v-col cols="6">
                          <validation-provider
                            vid="transfer_data.transfer_type"
                            :name="$t('transfer type')"
                            rules="required"
                            v-slot="{ errors, classes, dirty, valid }"
                          >
                            <v-select
                              v-model="transferData.transfer_type"
                              name="transfer_data.transfer_type"
                              :label="$t('transfer type')"
                              :items="transferTypes"
                              item-text="text"
                              item-value="value"
                              :error-messages="errors"
                              :success="dirty && valid"
                              :class="classes"
                              :clearable="!isMobile"
                              disabled
                              show-size
                            />
                          </validation-provider>
                        </v-col>
                        <v-col cols="6"> </v-col>
                      </v-row>
                      -->
                    </v-card>

                    <v-card
                      class="px-6 mt-4"
                      outlined
                      v-if="transferPhoneNumber"
                    >
                      <v-row>
                        <v-col cols="6">
                          <validation-provider
                            vid="transfer_data.poa_document"
                            :name="$t('POA document')"
                            :rules="
                              'required' +
                              (poaDocument != null ? '|size:1000' : '')
                            "
                            v-slot="{ errors, classes, dirty, valid }"
                          >
                            <v-file-input
                              v-model="poaDocument"
                              name="transfer_data.poa_document"
                              :label="$t('POA document')"
                              :hint="$t('poaDocumentHint')"
                              :error-messages="errors"
                              :success="dirty && valid"
                              :class="classes"
                              accept="application/pdf"
                              show-size
                              persistent-hint
                            >
                              <v-tooltip
                                slot="append"
                                top
                                v-if="
                                  transferPhoneNumber &&
                                  ((isWho && $config.mobileTransferFormWho) ||
                                    $config.mobileTransferForm)
                                "
                              >
                                <template v-slot:activator="{ on }">
                                  <v-btn
                                    v-on="on"
                                    :href="
                                      isWho
                                        ? $config.mobileTransferFormWho
                                        : $config.mobileTransferForm
                                    "
                                    icon
                                    download
                                  >
                                    <v-icon>mdi-file-edit-outline</v-icon>
                                  </v-btn>
                                </template>
                                <span>{{ $t("POA document template") }}</span>
                              </v-tooltip>
                            </v-file-input>
                          </validation-provider>
                        </v-col>
                        <v-col cols="6">
                          <date-picker
                            v-model="transferData.poa_date"
                            name="transfer_data.poa_date"
                            :label="$t('POA date')"
                            rules="required"
                            :allowedDates="allowedDatesPoa"
                          />
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="6">
                          <validation-provider
                            vid="transfer_data.poa_signed_by"
                            :name="$t('POA signed by')"
                            rules="required|max:100"
                            v-slot="{ errors, classes, dirty, valid }"
                          >
                            <v-text-field
                              v-model="transferData.poa_signed_by"
                              name="transfer_data.poa_signed_by"
                              :label="$t('POA signed by')"
                              :error-messages="errors"
                              :success="dirty && valid"
                              :class="classes"
                              :clearable="!isMobile"
                              prepend-icon="mdi-signature-freehand"
                              counter="100"
                            />
                          </validation-provider>
                        </v-col>
                        <v-col cols="6"> </v-col>
                      </v-row>
                    </v-card>

                    <v-card
                      class="px-6 mt-4"
                      flat
                      v-if="
                        transferPhoneNumber &&
                        ($store.getters.isStaffAdmin || !isWho)
                      "
                    >
                      <v-row>
                        <v-col cols="12">
                          <validation-provider
                            vid="transfer_data.use_specific_subscriber_address"
                            :name="$t('use specific subscriber address')"
                            v-slot="{ errors, valid, dirty, classes }"
                          >
                            <v-switch
                              v-model="
                                transferData.use_specific_subscriber_address
                              "
                              name="transfer_data.use_specific_subscriber_address"
                              :label="$t('use specific subscriber address')"
                              :error-messages="errors"
                              :class="classes"
                            />
                          </validation-provider>
                        </v-col>
                      </v-row>
                    </v-card>

                    <v-row
                      v-if="
                        transferPhoneNumber &&
                        transferData.use_specific_subscriber_address
                      "
                    >
                      <v-col cols="12">
                        <v-card outlined class="px-6">
                          <v-row>
                            <v-col cols="12">
                              <validation-provider
                                vid="transfer_data.subscriber_address.company"
                                :name="$t('company')"
                                rules="max:100"
                                v-slot="{ errors, valid, dirty, classes }"
                              >
                                <v-text-field
                                  v-model="
                                    transferData.subscriber_address.company
                                  "
                                  name="transfer_data.subscriber_address.company"
                                  :label="$t('company')"
                                  :error-messages="errors"
                                  :success="dirty && valid"
                                  :class="classes"
                                  counter="100"
                                  :clearable="!isMobile"
                                />
                              </validation-provider>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col cols="6">
                              <validation-provider
                                vid="transfer_data.subscriber_address.first_name"
                                :name="$t('first name')"
                                rules="max:100"
                                v-slot="{ errors, valid, dirty, classes }"
                              >
                                <v-text-field
                                  v-model="
                                    transferData.subscriber_address.first_name
                                  "
                                  name="transfer_data.subscriber_address.first_name"
                                  :label="$t('first name')"
                                  :error-messages="errors"
                                  :success="dirty && valid"
                                  :class="classes"
                                  counter="100"
                                  :clearable="!isMobile"
                                />
                              </validation-provider>
                            </v-col>
                            <v-col cols="6">
                              <validation-provider
                                vid="transfer_data.subscriber_address.last_name"
                                :name="$t('last name')"
                                rules="max:100"
                                v-slot="{ errors, valid, dirty, classes }"
                              >
                                <v-text-field
                                  v-model="
                                    transferData.subscriber_address.last_name
                                  "
                                  name="account_data.subscriber_address.last_name"
                                  :label="$t('last name')"
                                  :error-messages="errors"
                                  :success="dirty && valid"
                                  :class="classes"
                                  counter="100"
                                  :clearable="!isMobile"
                                />
                              </validation-provider>
                            </v-col>
                          </v-row>
                        </v-card>
                      </v-col>
                    </v-row>
                    <v-row
                      v-if="
                        transferPhoneNumber &&
                        transferData.use_specific_subscriber_address
                      "
                    >
                      <v-col cols="12">
                        <v-card outlined class="px-6">
                          <address-postal
                            v-model="transferData.subscriber_address"
                            additional-max-length="255"
                            :zip-max-length="10"
                            zip-name="zip_code"
                            zip-rules="required"
                            :city-max-length="100"
                            city-rules="required"
                            :street-max-length="100"
                            street-rules="required"
                            :street-number-max-length="10"
                            street-number-rules="required"
                            country-hide
                            name-prefix="transfer_data.subscriber_address."
                          />
                        </v-card>
                      </v-col>
                    </v-row>

                    <!--
                    <v-sheet
                      v-if="transferPhoneNumber && $store.getters.isStaffAdmin"
                      class="mt-4"
                      outlined
                      color="staff"
                      rounded
                    >
                      <v-card class="px-6" outlined>
                        <v-row dense>
                          <v-col cols="6">
                            <validation-provider
                              vid="transfer_data.is_internal_transfer"
                              :name="$t('is internal transfer')"
                              v-slot="{ errors, valid, dirty, classes }"
                            >
                              <v-checkbox
                                v-model="transferData.is_internal_transfer"
                                name="transfer_data.is_internal_transfer"
                                :label="$t('is internal transfer')"
                                :error-messages="errors"
                              />
                            </validation-provider>
                          </v-col>
                          <v-col cols="6">
                            <validation-provider
                              vid="transfer_data.handle_transfer_manually"
                              :name="$t('handle transfer manually')"
                              v-slot="{ errors, valid, dirty, classes }"
                            >
                              <v-checkbox
                                v-model="transferData.handle_transfer_manually"
                                name="transfer_data.handle_transfer_manually"
                                :label="$t('handle transfer manually')"
                                :error-messages="errors"
                                :class="classes"
                              />
                            </validation-provider>
                          </v-col>
                        </v-row>

                        <v-row dense>
                          <v-col cols="6">
                            <validation-provider
                              vid="transfer_data.is_emergency_porting"
                              :name="$t('is emergency porting')"
                              v-slot="{ errors, valid, dirty, classes }"
                            >
                              <v-checkbox
                                v-model="transferData.is_emergency_porting"
                                name="transfer_data.is_emergency_porting"
                                :label="$t('is emergency porting')"
                                :error-messages="errors"
                                :success="dirty && valid"
                                :class="classes"
                              />
                            </validation-provider>
                          </v-col>
                          <v-col cols="6"> </v-col>
                        </v-row>
                      </v-card>
                    </v-sheet>
                    -->
                  </v-card-text>
                  <v-card-actions>
                    <v-btn @click="step = 3" text>
                      {{ $t("back") }}
                    </v-btn>
                    <v-spacer />
                    <v-btn
                      color="primary"
                      @click="step = 5"
                      :disabled="invalid || loading"
                      text
                    >
                      {{ $t("continue") }}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </validation-observer>
            </v-stepper-content>

            <!-- ----------------------- step 5 - options ----------------------- -->
            <v-stepper-content step="5">
              <validation-observer
                ref="obsStep5"
                v-slot="{ errors, invalid, validated }"
              >
                <v-card outlined color="transparent">
                  <v-card-text>
                    <v-card outlined>
                      <v-toolbar color="titleBar" dark flat dense>
                        <v-toolbar-title>
                          {{ $t("primary SIM card") }}
                        </v-toolbar-title>
                      </v-toolbar>
                      <v-card-text class="px-6">
                        <v-row>
                          <v-col cols="12">
                            <validation-provider
                              vid="primary_sim_type"
                              :name="$t('primary SIM type')"
                              rules="required"
                              v-slot="{ errors, valid, dirty, classes }"
                            >
                              <v-select
                                v-model="primarySimType"
                                :items="primarySimTypes"
                                name="primary_sim_type"
                                :label="$t('primary SIM type')"
                                :hint="$t('type of primary SIM card')"
                                :error-messages="errors"
                                :success="dirty && valid"
                                :class="classes"
                              />
                            </validation-provider>
                          </v-col>
                        </v-row>
                        <v-row v-if="!transferPhoneNumber">
                          <v-col cols="12">
                            <phone-select-number
                              :base-product-id="baseProductId"
                              :label="$t('primary phone number')"
                              :hint="
                                $t(
                                  'optional wish number for your primary SIM card'
                                )
                              "
                              @select="wishMobileNumber = $event"
                            />
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>

                    <v-card class="mt-4" outlined>
                      <v-toolbar color="titleBar" dark flat dense>
                        <v-toolbar-title>
                          {{ $t("additional SIM cards") }}
                        </v-toolbar-title>
                      </v-toolbar>
                      <v-card-text class="px-6">
                        <v-row>
                          <v-col cols="12">
                            <v-chip
                              v-for="num in additionalSimArray"
                              :key="num"
                              outlined
                              class="mr-2 mb-2"
                              close
                              large
                              @click:close="additionalSimCount -= 1"
                            >
                              <v-icon right class="mr-1"> mdi-sim </v-icon>
                              {{ $t("extra data SIM") + " " + (num + 1) }}
                            </v-chip>
                            <v-chip
                              v-for="num in additionalEsimArray"
                              :key="'e' + num"
                              outlined
                              class="mr-2 mb-2"
                              close
                              large
                              @click:close="additionalEsimCount -= 1"
                            >
                              <v-icon right class="mr-1">
                                mdi-sim-outline
                              </v-icon>
                              {{ $t("extra data eSIM") + " " + (num + 1) }}
                            </v-chip>
                          </v-col>
                        </v-row>
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer />
                        <v-btn
                          color="primary"
                          text
                          :disabled="
                            additionalSimCount + additionalEsimCount >= 2
                          "
                          @click="additionalSimCount += 1"
                        >
                          {{ $t("add phys. data SIM") }}
                        </v-btn>
                        <v-btn
                          color="primary"
                          text
                          :disabled="
                            additionalSimCount + additionalEsimCount >= 2
                          "
                          @click="additionalEsimCount += 1"
                        >
                          {{ $t("add data eSIM") }}
                        </v-btn>
                      </v-card-actions>
                    </v-card>

                    <v-card
                      outlined
                      class="mt-6"
                      v-if="!isWho && productItem != null"
                    >
                      <v-simple-table dense>
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th colspan="2" class="text-overline">
                                {{ $t("costs") }}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-if="
                                productItem != null &&
                                productItem.gfo_variant &&
                                productItem.gfo_variant.multi_sim_variant &&
                                additionalSimCount > 0 &&
                                productItem.gfo_variant.multi_sim_variant
                                  .price_rec_incl
                              "
                            >
                              <td>
                                {{
                                  productItem.gfo_variant &&
                                  productItem.gfo_variant.multi_sim_variant
                                    .subscription
                                }}
                                {{ $t("monthly") }}:
                              </td>
                              <td>
                                {{
                                  additionalSimCount +
                                  " x " +
                                  formatMoney(
                                    productItem.gfo_variant.multi_sim_variant
                                      .price_rec_incl,
                                    "CHF"
                                  )
                                }}
                              </td>
                            </tr>
                            <tr
                              v-if="
                                productItem != null &&
                                productItem.gfo_variant &&
                                productItem.gfo_variant.multi_sim_variant &&
                                additionalSimCount > 0 &&
                                productItem.gfo_variant.multi_sim_variant
                                  .price_fix_incl
                              "
                            >
                              <td>
                                {{
                                  productItem.gfo_variant &&
                                  productItem.gfo_variant.multi_sim_variant
                                    .subscription
                                }}
                                {{ $t("unique") }}:
                              </td>
                              <td>
                                {{
                                  additionalSimCount +
                                  " x " +
                                  formatMoney(
                                    productItem.gfo_variant.multi_sim_variant
                                      .price_fix_incl,
                                    "CHF"
                                  )
                                }}
                              </td>
                            </tr>
                            <tr
                              v-if="
                                productItem != null &&
                                productItem.gfo_variant &&
                                productItem.gfo_variant.multi_esim_variant &&
                                additionalEsimCount > 0 &&
                                productItem.gfo_variant.multi_esim_variant
                                  .price_rec_incl
                              "
                            >
                              <td>
                                {{
                                  productItem.gfo_variant &&
                                  productItem.gfo_variant.multi_esim_variant
                                    .subscription
                                }}
                                {{ $t("monthly") }}:
                              </td>
                              <td>
                                {{
                                  additionalEsimCount +
                                  " x " +
                                  formatMoney(
                                    productItem.gfo_variant.multi_esim_variant
                                      .price_rec_incl,
                                    "CHF"
                                  )
                                }}
                              </td>
                            </tr>
                            <tr
                              v-if="
                                productItem != null &&
                                productItem.gfo_variant &&
                                productItem.gfo_variant.multi_esim_variant &&
                                additionalEsimCount > 0 &&
                                productItem.gfo_variant.multi_esim_variant
                                  .price_fix_incl
                              "
                            >
                              <td>
                                {{
                                  productItem.gfo_variant &&
                                  productItem.gfo_variant.multi_esim_variant
                                    .subscription
                                }}
                                {{ $t("unique") }}:
                              </td>
                              <td>
                                {{
                                  additionalEsimCount +
                                  " x " +
                                  formatMoney(
                                    productItem.gfo_variant.multi_esim_variant
                                      .price_fix_incl,
                                    "CHF"
                                  )
                                }}
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-card>

                    <v-alert
                      class="ma-6"
                      outlined
                      prominent
                      text
                      type="info"
                      v-if="
                        productItem != null &&
                        productItem.gfo_variant &&
                        productItem.gfo_variant.multi_esim_variant &&
                        (additionalEsimCount > 0 || primarySimType == 'esim')
                      "
                      v-html="
                        productItem.gfo_variant.multi_esim_variant.summary
                      "
                    />
                  </v-card-text>
                  <v-card-actions>
                    <v-btn @click="step = 4" text>
                      {{ $t("back") }}
                    </v-btn>
                    <v-spacer />
                    <v-btn
                      color="primary"
                      @click="step = 6"
                      :disabled="invalid || loading"
                      text
                    >
                      {{ $t("continue") }}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </validation-observer>
            </v-stepper-content>

            <!-- ----------------------- step 6 - delivery ----------------------- -->
            <v-stepper-content step="6">
              <validation-observer
                ref="obsStep6"
                v-slot="{ errors, invalid, validated }"
              >
                <v-card outlined color="transparent">
                  <v-card-text>
                    <hardware-shop-delivery
                      v-model="deliveryAddress"
                      :validation-data="orderData"
                      validationUrl="services/phone/mobile-create"
                      :receipt="deliveryReceipt"
                      :default-address="defaultDeliveryAddress"
                      show-delete-address
                      show-reset-address
                      show-receipt-preview
                      show-receipt-edit
                      @receiptSaved="deliveryReceipt = $event"
                    />

                    <v-card class="px-6 mt-4" outlined>
                      <v-row>
                        <v-col cols="6">
                          <date-picker
                            v-model="deliveryDate"
                            name="delivery_date"
                            :label="$t('delivery date')"
                            :clearable="!isMobile"
                            :allowedDates="allowedDatesDelivery"
                            show-today-as-asap
                            show-today-btn
                            :today-btn-text="$t('asap.')"
                          />
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="12">
                          <email-list-input
                            v-model="email"
                            name="email"
                            :label="$t('email')"
                            :hint="$t('email for order confirmation')"
                            rules="max:1024"
                          />
                        </v-col>
                      </v-row>
                      <v-row v-if="$store.getters.isStaff || isWho">
                        <v-col cols="12">
                          <validation-provider
                            vid="product_description"
                            :name="$t('product description')"
                            rules="max:100"
                            v-slot="{ errors, valid, dirty, classes }"
                          >
                            <v-text-field
                              v-model="productDescription"
                              name="product_description"
                              :label="$t('product description')"
                              :error-messages="errors"
                              :success="dirty && valid"
                              :class="{
                                ...classes,
                                'staff--text': !isWho,
                              }"
                              counter="100"
                              :clearable="!isMobile"
                            />
                          </validation-provider>
                        </v-col>
                      </v-row>
                      <v-row v-if="$store.getters.isStaff || isWho">
                        <v-col cols="12">
                          <validation-provider
                            vid="note"
                            :name="$t('order note')"
                            rules="max:1024"
                            v-slot="{ errors, valid, dirty, classes }"
                          >
                            <v-textarea
                              v-model="orderNote"
                              name="order_note"
                              :label="$t('order note')"
                              :error-messages="errors"
                              :success="dirty && valid"
                              :class="{
                                ...classes,
                                'staff--text': !isWho,
                              }"
                              :clearable="!isMobile"
                              maxlength="1024"
                              counter
                              rows="1"
                              auto-grow
                            />
                          </validation-provider>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-card-text>

                  <v-card-actions>
                    <v-btn @click="step = 5" text>
                      {{ $t("back") }}
                    </v-btn>
                    <v-spacer />
                    <v-btn
                      color="primary"
                      @click="step = 7"
                      :disabled="invalid || loading"
                      text
                    >
                      {{ $t("continue") }}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </validation-observer>
            </v-stepper-content>

            <!-- ----------------------- step 7 - check ----------------------- -->
            <v-stepper-content step="7">
              <validation-observer
                ref="obsStep7"
                v-slot="{ errors, invalid, validated }"
              >
                <v-card class="mb-12" outlined color="transparent">
                  <v-card-text>
                    <v-card outlined>
                      <v-simple-table dense>
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th colspan="2" class="text-overline">
                                {{ $t("your subscription") }}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>{{ $t("subscription") }}:</td>
                              <td>{{ productName }}</td>
                            </tr>
                            <tr>
                              <td>{{ $t("primary SIM card type") }}:</td>
                              <td>{{ $t(primarySimType) }}</td>
                            </tr>
                            <tr v-if="!transferPhoneNumber && wishMobileNumber">
                              <td>{{ $t("primary SIM card wishnumber") }}:</td>
                              <td>{{ wishMobileNumber.number }}</td>
                            </tr>
                            <tr>
                              <td>{{ $t("additional phys. SIM cards") }}:</td>
                              <td>{{ additionalSimCount }}</td>
                            </tr>
                            <tr>
                              <td>{{ $t("additional eSIM cards") }}:</td>
                              <td>{{ additionalEsimCount }}</td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-card>

                    <v-card
                      outlined
                      class="mt-6"
                      v-if="!isWho && productItem != null"
                    >
                      <v-simple-table dense>
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th colspan="2" class="text-overline">
                                {{ $t("costs") }}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                {{ $t("subscription price") }}
                                {{ $t("monthly") }}:
                              </td>
                              <td>
                                {{
                                  productItem.gfo_variant &&
                                  productItem.gfo_variant.price_rec_incl
                                    ? formatMoney(
                                        productItem.gfo_variant.price_rec_incl,
                                        "CHF"
                                      )
                                    : "-"
                                }}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                {{ $t("subscription setup fee") }}
                                {{ $t("unique") }}:
                              </td>
                              <td>
                                {{
                                  productItem.gfo_variant &&
                                  productItem.gfo_variant.price_fix_incl
                                    ? formatMoney(
                                        productItem.gfo_variant.price_fix_incl,
                                        "CHF"
                                      )
                                    : "-"
                                }}
                              </td>
                            </tr>
                            <tr
                              v-if="
                                productItem != null &&
                                productItem.gfo_variant &&
                                productItem.gfo_variant.promo_variant
                              "
                            >
                              <td>
                                {{
                                  productItem.gfo_variant &&
                                  productItem.gfo_variant.promo_variant
                                    .subscription
                                }}:
                              </td>
                              <td>
                                {{
                                  productItem.gfo_variant.promo_variant
                                    .price_fix_incl
                                    ? formatMoney(
                                        productItem.gfo_variant.promo_variant
                                          .price_fix_incl,
                                        "CHF"
                                      )
                                    : "-"
                                }}
                              </td>
                            </tr>
                            <tr
                              v-if="
                                productItem != null &&
                                productItem.gfo_variant &&
                                productItem.gfo_variant.variant_porting &&
                                transferPhoneNumber &&
                                productItem.gfo_variant.variant_porting
                                  .price_rec_incl
                              "
                            >
                              <td>
                                {{
                                  productItem.gfo_variant &&
                                  productItem.gfo_variant.variant_porting
                                    .subscription
                                }}
                                {{ $t("monthly") }}:
                              </td>
                              <td>
                                {{
                                  formatMoney(
                                    productItem.gfo_variant.variant_porting
                                      .price_rec_incl,
                                    "CHF"
                                  )
                                }}
                              </td>
                            </tr>
                            <tr
                              v-if="
                                productItem != null &&
                                productItem.gfo_variant &&
                                productItem.gfo_variant.variant_porting &&
                                transferPhoneNumber &&
                                productItem.gfo_variant.variant_porting
                                  .price_fix_incl
                              "
                            >
                              <td>
                                {{
                                  productItem.gfo_variant &&
                                  productItem.gfo_variant.variant_porting
                                    .subscription
                                }}
                                {{ $t("unique") }}:
                              </td>
                              <td>
                                {{
                                  formatMoney(
                                    productItem.gfo_variant.variant_porting
                                      .price_fix_incl,
                                    "CHF"
                                  )
                                }}
                              </td>
                            </tr>
                            <tr
                              v-if="
                                productItem != null &&
                                productItem.gfo_variant &&
                                productItem.gfo_variant.multi_sim_variant &&
                                additionalSimCount > 0 &&
                                productItem.gfo_variant.multi_sim_variant
                                  .price_rec_incl
                              "
                            >
                              <td>
                                {{
                                  productItem.gfo_variant &&
                                  productItem.gfo_variant.multi_sim_variant
                                    .subscription
                                }}
                                {{ $t("monthly") }}:
                              </td>
                              <td>
                                {{
                                  additionalSimCount +
                                  " x " +
                                  formatMoney(
                                    productItem.gfo_variant.multi_sim_variant
                                      .price_rec_incl,
                                    "CHF"
                                  )
                                }}
                              </td>
                            </tr>
                            <tr
                              v-if="
                                productItem != null &&
                                productItem.gfo_variant &&
                                productItem.gfo_variant.multi_sim_variant &&
                                additionalSimCount > 0 &&
                                productItem.gfo_variant.multi_sim_variant
                                  .price_fix_incl
                              "
                            >
                              <td>
                                {{
                                  productItem.gfo_variant &&
                                  productItem.gfo_variant.multi_sim_variant
                                    .subscription
                                }}
                                {{ $t("unique") }}:
                              </td>
                              <td>
                                {{
                                  additionalSimCount +
                                  " x " +
                                  formatMoney(
                                    productItem.gfo_variant.multi_sim_variant
                                      .price_fix_incl,
                                    "CHF"
                                  )
                                }}
                              </td>
                            </tr>
                            <tr
                              v-if="
                                productItem != null &&
                                productItem.gfo_variant &&
                                productItem.gfo_variant.multi_esim_variant &&
                                additionalEsimCount > 0 &&
                                productItem.gfo_variant.multi_esim_variant
                                  .price_rec_incl
                              "
                            >
                              <td>
                                {{
                                  productItem.gfo_variant &&
                                  productItem.gfo_variant.multi_esim_variant
                                    .subscription
                                }}
                                {{ $t("monthly") }}:
                              </td>
                              <td>
                                {{
                                  additionalEsimCount +
                                  " x " +
                                  formatMoney(
                                    productItem.gfo_variant.multi_esim_variant
                                      .price_rec_incl,
                                    "CHF"
                                  )
                                }}
                              </td>
                            </tr>
                            <tr
                              v-if="
                                productItem != null &&
                                productItem.gfo_variant &&
                                productItem.gfo_variant.multi_esim_variant &&
                                additionalEsimCount > 0 &&
                                productItem.gfo_variant.multi_esim_variant
                                  .price_fix_incl
                              "
                            >
                              <td>
                                {{
                                  productItem.gfo_variant &&
                                  productItem.gfo_variant.multi_esim_variant
                                    .subscription
                                }}
                                {{ $t("unique") }}:
                              </td>
                              <td>
                                {{
                                  additionalEsimCount +
                                  " x " +
                                  formatMoney(
                                    productItem.gfo_variant.multi_esim_variant
                                      .price_fix_incl,
                                    "CHF"
                                  )
                                }}
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-card>

                    <v-card outlined class="mt-6">
                      <v-simple-table dense>
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th colspan="2" class="text-overline">
                                {{
                                  isWho
                                    ? $t("end customer account data")
                                    : $t("customer account data")
                                }}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>{{ $t("is corporate") }}:</td>
                              <td>
                                <boolean-value
                                  :value="accountData.corporate"
                                  color
                                />
                              </td>
                            </tr>
                            <tr v-if="accountData.corporate">
                              <td>{{ $t("company") }}:</td>
                              <td>{{ accountData.company }}</td>
                            </tr>
                            <tr v-else>
                              <td>{{ $t("name") }}:</td>
                              <td>
                                {{ accountData.first_name }}
                                {{ accountData.last_name }}
                              </td>
                            </tr>
                            <tr>
                              <td>{{ $t("address") }}:</td>
                              <td>
                                {{ accountData.address.additional }}
                                <br v-if="accountData.address.additional" />
                                {{ accountData.address.street }}
                                {{ accountData.address.street_number }}
                                <br />
                                {{ accountData.address.zip_code }}
                                {{ accountData.address.city }}
                                <br />
                                {{ accountData.address.country }}
                              </td>
                            </tr>
                            <tr v-if="accountData.language">
                              <td>{{ $t("language") }}:</td>
                              <td>{{ $t("lang_" + accountData.language) }}</td>
                            </tr>
                            <tr v-if="accountData.nationality">
                              <td>{{ $t("nationality") }}:</td>
                              <td>{{ accountData.nationality }}</td>
                            </tr>
                            <tr v-if="accountData.date_of_birth">
                              <td>{{ $t("date of birth") }}:</td>
                              <td>
                                {{ formatDate(accountData.date_of_birth) }}
                              </td>
                            </tr>
                            <tr v-if="accountData.email">
                              <td>{{ $t("email") }}:</td>
                              <td>{{ accountData.email }}</td>
                            </tr>
                            <tr v-if="accountData.mobile">
                              <td>{{ $t("mobile number") }}:</td>
                              <td>{{ accountData.mobile }}</td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-card>

                    <v-card outlined class="mt-6">
                      <v-simple-table dense>
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th colspan="2" class="text-overline">
                                {{ $t("user identify data") }}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-if="isCorporate">
                              <td>{{ $t("name") }}:</td>
                              <td>
                                {{ accountData.first_name }}
                                {{ accountData.last_name }}
                              </td>
                            </tr>
                            <template v-if="identityData.check_manual">
                              <tr v-if="accountData.date_of_birth">
                                <td>{{ $t("date of birth") }}:</td>
                                <td>
                                  {{ formatDate(accountData.date_of_birth) }}
                                </td>
                              </tr>
                              <tr>
                                <td>{{ $t("nationality") }}:</td>
                                <td>{{ accountData.nationality }}</td>
                              </tr>
                              <tr>
                                <td>{{ $t("identity document type") }}:</td>
                                <td>{{ $t(identityData.check_type) }}</td>
                              </tr>
                              <tr>
                                <td>{{ $t("identity document number") }}:</td>
                                <td>{{ identityData.identity_number }}</td>
                              </tr>
                              <tr v-if="identityDocument">
                                <td>{{ $t("identity document") }}:</td>
                                <td>{{ identityDocument.name }}</td>
                              </tr>
                            </template>
                            <template v-else>
                              <tr v-if="identityData.mfa_verification_type">
                                <td>
                                  {{ $t("multi factor authentication type") }}:
                                </td>
                                <td>
                                  {{ $t(identityData.mfa_verification_type) }}
                                </td>
                              </tr>
                              <tr v-if="identityData.mfa_verification_type">
                                <td>
                                  {{
                                    identityData.mfa_verification_type ==
                                    "EMAIL"
                                      ? $t("authentication email address")
                                      : identityData.mfa_verification_type ==
                                        "SMS"
                                      ? $t("authentication SMS number")
                                      : $t("authentication target")
                                  }}:
                                </td>
                                <td>
                                  {{
                                    identityData.mfa_verification_type ==
                                    "EMAIL"
                                      ? identityData.mfa_target_email
                                      : identityData.mfa_target_sms
                                  }}
                                </td>
                              </tr>
                            </template>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-card>

                    <v-card outlined class="mt-6" v-if="transferPhoneNumber">
                      <v-simple-table dense>
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th colspan="2" class="text-overline">
                                {{ $t("number transfer data") }}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>{{ $t("transfer number") }}:</td>
                              <td>
                                {{ transferData.phone_number }}
                              </td>
                            </tr>
                            <tr>
                              <td>{{ $t("transfer date") }}:</td>
                              <td>
                                {{
                                  transferData.transfer_date
                                    ? formatDate(transferData.transfer_date)
                                    : $t("asap.")
                                }}
                                {{
                                  transferData.transfer_time
                                    ? formatTime(transferData.transfer_time)
                                    : ""
                                }}
                              </td>
                            </tr>
                            <tr v-if="providerName">
                              <td>{{ $t("provider") }}:</td>
                              <td>
                                {{ providerName }}
                              </td>
                            </tr>
                            <tr v-if="transferData.connection_type">
                              <td>{{ $t("contract type") }}:</td>
                              <td>
                                {{ $t(transferData.connection_type) }}
                              </td>
                            </tr>
                            <tr v-if="transferData.transfer_type">
                              <td>{{ $t("transfer type") }}:</td>
                              <td>{{ $t(transferData.transfer_type) }}</td>
                            </tr>
                            <tr v-if="transferData.transfer_priority">
                              <td>{{ $t("transfer priority") }}:</td>
                              <td>{{ $t(transferData.transfer_priority) }}</td>
                            </tr>
                            <tr v-if="poaDocument">
                              <td>{{ $t("POA document") }}:</td>
                              <td>{{ poaDocument.name }}</td>
                            </tr>
                            <tr v-if="transferData.poa_date">
                              <td>{{ $t("POA date") }}:</td>
                              <td>
                                {{
                                  transferData.poa_date
                                    ? formatDate(transferData.poa_date)
                                    : $t("asap.")
                                }}
                              </td>
                            </tr>
                            <tr>
                              <td>{{ $t("POA signed by") }}:</td>
                              <td>{{ transferData.poa_signed_by }}</td>
                            </tr>
                            <tr
                              v-if="
                                transferData.use_specific_subscriber_address &&
                                transferData.subscriber_address.company
                              "
                            >
                              <td>{{ $t("subscriber company") }}:</td>
                              <td>
                                {{ transferData.subscriber_address.company }}
                              </td>
                            </tr>
                            <tr
                              v-if="
                                transferData.use_specific_subscriber_address &&
                                (transferData.subscriber_address.first_name ||
                                  transferData.subscriber_address.last_name)
                              "
                            >
                              <td>{{ $t("subscriber name") }}:</td>
                              <td>
                                {{ transferData.subscriber_address.first_name }}
                                {{ transferData.subscriber_address.last_name }}
                              </td>
                            </tr>
                            <tr
                              v-if="
                                transferData.use_specific_subscriber_address
                              "
                            >
                              <td>{{ $t("subscriber address") }}:</td>
                              <td>
                                {{ transferData.subscriber_address.additional }}
                                <br
                                  v-if="
                                    transferData.subscriber_address.additional
                                  "
                                />
                                {{ transferData.subscriber_address.street }}
                                {{
                                  transferData.subscriber_address.street_number
                                }}
                                <br />
                                {{ transferData.subscriber_address.zip_code }}
                                {{ transferData.subscriber_address.city }}
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-card>

                    <v-card outlined class="mt-6">
                      <v-simple-table dense>
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th colspan="2" class="text-overline">
                                {{ $t("delivery address") }}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-if="deliveryAddress.is_corporate">
                              <td>{{ $t("company") }}:</td>
                              <td>
                                {{ deliveryAddress.company }}
                              </td>
                            </tr>
                            <tr
                              v-else-if="
                                deliveryAddress.first_name ||
                                deliveryAddress.last_name
                              "
                            >
                              <td>{{ $t("name") }}:</td>
                              <td>
                                {{ deliveryAddress.first_name }}
                                {{ deliveryAddress.last_name }}
                              </td>
                            </tr>
                            <tr>
                              <td>{{ $t("address") }}:</td>
                              <td>
                                {{ deliveryAddress.additional }}
                                <br v-if="deliveryAddress.additional" />
                                {{ deliveryAddress.street }}
                                {{ deliveryAddress.street_number }}
                                <br />
                                {{ deliveryAddress.zip_code }}
                                {{ deliveryAddress.city }}
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-card>

                    <v-card outlined class="mt-6">
                      <v-simple-table dense>
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th colspan="2" class="text-overline">
                                {{ $t("additional order data") }}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>{{ $t("delivery date") }}:</td>
                              <td>
                                {{
                                  deliveryDate
                                    ? formatDate(deliveryDate)
                                    : $t("asap.")
                                }}
                              </td>
                            </tr>
                            <tr v-if="productDescription">
                              <td>{{ $t("product description") }}:</td>
                              <td>{{ productDescription }}</td>
                            </tr>
                            <tr v-if="orderNote">
                              <td>{{ $t("order note") }}:</td>
                              <td>{{ orderNote }}</td>
                            </tr>
                            <tr v-if="email">
                              <td>{{ $t("email for order confirmation") }}:</td>
                              <td>{{ email }}</td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-card>

                    <v-alert
                      class="ma-6"
                      outlined
                      prominent
                      text
                      type="info"
                      v-if="!isWho"
                    >
                      <div
                        class="text-justify"
                        v-html="productItem.gfo_variant.network_terms"
                        v-if="
                          productItem &&
                          productItem.gfo_variant &&
                          productItem.gfo_variant.network_terms
                        "
                      />
                      <div
                        class="text-justify"
                        v-html="productItem.gfo_variant.terms"
                        v-if="
                          productItem &&
                          productItem.gfo_variant &&
                          productItem.gfo_variant.terms
                        "
                      />
                      <v-switch
                        v-model="termsConfirmed"
                        :label="$t('confirm subscription terms')"
                      />
                    </v-alert>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn @click="step = 6" text :disabled="loadingOrder">
                      {{ $t("back") }}
                    </v-btn>
                    <v-spacer />
                    <v-btn
                      color="primary"
                      @click="order"
                      :loading="loadingOrder"
                      :disabled="
                        invalid || loading || (!termsConfirmed && !isWho)
                      "
                      text
                    >
                      {{ $t("order") }}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </validation-observer>
            </v-stepper-content>
          </validation-observer>
        </v-stepper-items>
      </v-stepper>
    </v-card-text>
  </v-card>
</template>

<script>
import AddressPostal from "@/components/basics/AddressPostal";
import AddressNames from "@/components/basics/AddressNames";
import AddressContacts from "@/components/basics/AddressContacts";
import LanguageSelect from "@/components/basics/LanguageSelect";
import CountrySelect from "@/components/basics/CountrySelect";
import DatePicker from "@/components/basics/DatePicker";
import TimePicker from "@/components/basics/TimePicker";
import BooleanValue from "@/components/basics/BooleanValue";
import formatDateTime from "@/utils/mixins/formatDateTime";
import formatDate from "@/utils/mixins/formatDate";
import formatPhoneNumber from "@/utils/mixins/formatPhoneNumber";
import formatTime from "@/utils/mixins/formatTime";
import formatMoney from "@/utils/mixins/formatMoney";
import EmailListInput from "@/components/basics/EmailListInput";
import showErrors from "@/utils/mixins/showErrors";
import isMobile from "@/utils/mixins/isMobile";
import isTeldasNoneWorkingDay from "@/utils/mixins/teldasNoneWorkingDays";
import downloadFile from "@/utils/mixins/downloadFile";
import PhoneSelectNumber from "./PhoneSelectNumber";
import CreateClientCustomer from "@/components/reseller/CreateClientCustomer";
import HardwareShopDelivery from "@/components/orders/hardware/HardwareShopDelivery";

export default {
  name: "PhoneMobileCreate",
  components: {
    DatePicker,
    TimePicker,
    BooleanValue,
    EmailListInput,
    AddressPostal,
    AddressNames,
    AddressContacts,
    LanguageSelect,
    CountrySelect,
    PhoneSelectNumber,
    CreateClientCustomer,
    HardwareShopDelivery,
  },
  mixins: [
    showErrors,
    formatDateTime,
    formatDate,
    formatTime,
    formatMoney,
    formatPhoneNumber,
    isMobile,
    downloadFile,
    isTeldasNoneWorkingDay,
  ],
  data() {
    return {
      step: 1,
      loadingProductCatalog: false,
      productCatalog: [],
      productId: null,
      baseProductId: null,
      deliveryDate: null,
      email: this.$store.getters.deliveryEmail,
      orderNote: "",
      productDescription: "",
      primarySimType: "sim",
      wishMobileNumber: null,
      additionalSimCount: 0,
      additionalEsimCount: 0,
      identityData: {
        check_manual: false,
        mfa_verification_type: "EMAIL",
        mfa_target_email: null,
        mfa_target_sms: null,
        check_type: "PASSPORT",
        identity_number: null,
      },
      transferPhoneNumber: true,
      transferData: {
        phone_number: null,
        is_internal_transfer: false,
        handle_transfer_manually: false,
        use_specific_subscriber_address: this.$store.getters.isWho
          ? true
          : false,
        subscriber_address: {
          is_coporate: null,
          company: null,
          first_name: null,
          last_name: null,
          additional: null,
          street: null,
          street_number: null,
          zip_code: null,
          city: null,
          country: null,
        },
        transfer_date: null,
        transfer_time: "10:00:00",
        poa_date: this.getTodayString(),
        poa_signed_by: null,
        provider_account_id: null,
        provider_account_name: null,
        provider_account_value: null,
        provider_account_search: null,
        connection_type: "MOBILE",
        transfer_type: "FULL",
        transfer_priority: "NORMAL",
        is_emergency_porting: false,
      },
      accountData: {
        salutation: null,
        company: null,
        first_name: null,
        last_name: null,
        corporate: null,
        language: "de",
        nationality: null,
        date_of_birth: null,
        email: null,
        mobile: null,
        address: {
          additional: null,
          street: null,
          street_number: null,
          zip_code: null,
          city: null,
          country: "CH",
        },
      },
      deliveryAddress: {},
      defaultDeliveryAddress: {},
      deliveryReceipt: "",
      poaDocument: null,
      poaDocumentBase64: null,
      identityDocument: null,
      identityDocumentBase64: null,
      providers: [],
      loadingProviders: false,
      loadingOrder: false,
      loadingReceipt: false,
      loadingPreview: false,
      termsConfirmed: false,
      onBehalfOf: null,
      onBehalfOrderType: "CUS",
      customers: [],
      loadingCustomers: false,
      createCustomerDialog: false,
      loadingCustomer: false,
    };
  },
  computed: {
    isWho() {
      return this.$store.getters.isWho;
    },
    isCorporate() {
      return this.isWho
        ? this.accountData.corporate
        : this.$store.getters.isCorporate;
    },
    additionalSimArray() {
      return [...Array(this.additionalSimCount).keys()];
    },
    additionalEsimArray() {
      return [...Array(this.additionalEsimCount).keys()];
    },
    loading() {
      return (
        this.loadingProductCatalog ||
        this.loadingProviders ||
        this.loadingOrder ||
        this.loadingValidate ||
        this.loadingReceipt ||
        this.loadingPreview ||
        this.loadingCustomers ||
        this.loadingCustomer
      );
    },
    productItem() {
      var that = this;
      this.productCatalog;
      this.productId;
      var productItem = null;
      this.productCatalog.forEach(function (item) {
        if (that.productId && item.id == that.productId) {
          productItem = item;
        }
      });
      return productItem;
    },
    productName() {
      var name = "";
      var item = this.productItem;
      if (item)
        name = item.gfo_variant ? item.gfo_variant.subscription : item.caption;
      return name;
    },
    providerName() {
      var that = this;
      var name = this.transferData.provider_account_name;
      this.providers.forEach(function (item) {
        if (item && item.id == that.transferData.provider_account_id)
          name = item.caption;
      });
      return name;
    },
    onBehalfOrderTypes() {
      return [
        {
          value: "CUS",
          text: this.$i18n.t("order is placed by your customer"),
        },
        {
          value: "PAR",
          text: this.$i18n.t("order happens through you"),
        },
      ];
    },
    primarySimTypes() {
      return [
        { text: this.$i18n.t("phys. SIM"), value: "sim" },
        { text: this.$i18n.t("eSIM"), value: "esim" },
      ];
    },
    mfaVerificationTypes() {
      return [
        { text: this.$i18n.t("EMAIL"), value: "EMAIL" },
        // do not provide SMS !!!
        // { text: this.$i18n.t("SMS"), value: "SMS" },
      ];
    },
    checkTypes() {
      return [
        { text: this.$i18n.t("PASSPORT"), value: "PASSPORT" },
        { text: this.$i18n.t("IDCARD"), value: "IDCARD" },
        { text: this.$i18n.t("OTHER"), value: "OTHER" },
      ];
    },
    connectionTypes() {
      return [
        { text: this.$i18n.t("MOBILE"), value: "MOBILE" },
        {
          text: this.$i18n.t("MOBILEPREPAID"),
          value: "MOBILEPREPAID",
        },
      ];
    },
    transferTypes() {
      return [
        { text: this.$i18n.t("FULL"), value: "FULL" },
        {
          text: this.$i18n.t("PARTIAL"),
          value: "PARTIAL",
          disabled: true,
        },
      ];
    },
    transferPriorities() {
      return [
        { text: this.$i18n.t("NORMAL"), value: "NORMAL" },
        { text: this.$i18n.t("FAST"), value: "FAST" },
      ];
    },
    transferTimeSlots() {
      return [
        { text: "08:00 - 09:00", value: "08:00:00" },
        { text: "09:00 - 10:00", value: "09:00:00" },
        { text: "10:00 - 11:00", value: "10:00:00" },
        { text: "11:00 - 12:00", value: "11:00:00" },
        { text: "13:00 - 14:00", value: "13:00:00" },
        { text: "14:00 - 15:00", value: "14:00:00" },
        { text: "15:00 - 16:00", value: "15:00:00" },
        { text: "16:00 - 17:00", value: "16:00:00" },
      ];
    },
    orderData() {
      return {
        on_behalf_of: this.onBehalfOf || null,
        on_behalf_order_type: this.onBehalfOrderType || null,
        product_id: this.productId,
        delivery_date: this.deliveryDate || null,
        email: this.email || null,
        product_description: this.productDescription || null,
        wish_mobile_number:
          this.wishMobileNumber && !this.transferPhoneNumber
            ? this.wishMobileNumber.number
            : null,
        primary_sim_type: this.primarySimType,
        add_esim_count: this.additionalEsimCount,
        add_sim_count: this.additionalSimCount,
        note: this.orderNote || null,
        transfer_phone_number: this.transferPhoneNumber,
        transfer_data: this.transferPhoneNumber
          ? {
              phone_number: this.transferData.phone_number,
              is_internal_transfer: this.transferData.is_internal_transfer,
              handle_transfer_manually:
                this.transferData.handle_transfer_manually,
              use_specific_subscriber_address:
                this.transferData.use_specific_subscriber_address,
              subscriber_address: this.transferData
                .use_specific_subscriber_address
                ? {
                    is_coporate:
                      this.transferData.subscriber_address.is_corporate || null,
                    company:
                      this.transferData.subscriber_address.company || null,
                    first_name:
                      this.transferData.subscriber_address.first_name || null,
                    last_name:
                      this.transferData.subscriber_address.last_name || null,
                    additional:
                      this.transferData.subscriber_address.additional || null,
                    street: this.transferData.subscriber_address.street || null,
                    street_number:
                      this.transferData.subscriber_address.street_number ||
                      null,
                    zip_code:
                      this.transferData.subscriber_address.zip_code || null,
                    city: this.transferData.subscriber_address.city || null,
                  }
                : null,
              transfer_date: this.transferData.transfer_date || null,
              transfer_time: this.transferData.transfer_time || null,
              poa_date: this.transferData.poa_date,
              poa_document: this.poaDocumentBase64,
              poa_signed_by: this.transferData.poa_signed_by || null,
              provider_account_id:
                this.transferData.provider_account_id || null,
              provider_account_name:
                this.transferData.provider_account_name || null,
              connection_type: this.transferData.connection_type,
              transfer_type: this.transferData.transfer_type,
              transfer_priority: this.transferData.transfer_priority,
              is_emergency_porting: this.transferData.is_emergency_porting,
            }
          : null,
        identity_data: this.identityData
          ? {
              check_manual: this.identityData.check_manual,
              mfa_verification_type: this.identityData.check_manual
                ? null
                : this.identityData.mfa_verification_type,
              mfa_target: this.identityData.check_manual
                ? null
                : this.identityData.mfa_verification_type == "EMAIL"
                ? this.identityData.mfa_target_email
                : this.identityData.mfa_target_sms,
              check_type: this.identityData.check_manual
                ? this.identityData.check_type
                : null,
              identity_number: this.identityData.check_manual
                ? this.identityData.identity_number
                : null,
              identity_document: this.identityData.check_manual
                ? this.identityDocumentBase64
                : null,
            }
          : null,
        account_data: this.accountData
          ? {
              company: this.accountData.company || null,
              first_name: this.accountData.first_name || null,
              last_name: this.accountData.last_name || null,
              additional: this.accountData.address.additional || null,
              street: this.accountData.address.street || null,
              street_number: this.accountData.address.street_number || null,
              zip_code: this.accountData.address.zip_code || null,
              city: this.accountData.address.city || null,
              country: this.accountData.address.country || null,
              corporate: this.accountData.corporate,
              language: this.accountData.language || null,
              nationality: this.accountData.nationality || null,
              date_of_birth: this.accountData.date_of_birth || null,
              email: this.accountData.email || null,
              mobile: this.accountData.mobile || null,
            }
          : null,
        delivery_address: this.deliveryAddress,
        delivery_receipt: this.deliveryReceipt,
      };
    },
  },
  watch: {
    baseProductId(value) {
      if (value) {
        this.getProviders();
      }
    },
    poaDocument(value) {
      if (value != null) {
        var that = this;
        const fileReader = new FileReader();
        fileReader.addEventListener("load", function (event) {
          that.poaDocumentBase64 = event.target.result;
        });
        fileReader.readAsDataURL(value);
      }
    },
    identityDocument(value) {
      var that = this;
      const fileReader = new FileReader();
      fileReader.addEventListener("load", function (event) {
        that.identityDocumentBase64 = event.target.result;
      });
      fileReader.readAsDataURL(value);
    },
    step(value) {
      // identity check
      if (value == 3) {
        if (this.accountData.email && !this.identityData.mfa_target_email)
          this.identityData.mfa_target_email = this.accountData.email;
        if (this.accountData.mobile && !this.identityData.mfa_target_sms)
          this.identityData.mfa_target_sms = this.accountData.mobile;
        // phone number transfer
      } else if (value == 4) {
        if (this.accountData.mobile && !this.transferData.phone_number)
          this.transferData.phone_number = this.accountData.mobile;
        if (this.deliveryDate && !this.transferData.transfer_date)
          this.transferData.transfer_date = this.deliveryDate;
        // delivery
      } else if (value == 6) {
        if (this.transferData.transfer_date && !this.deliveryDate)
          this.deliveryDate = this.transferData.transfer_date;
      }
    },
    onBehalfOf: {
      immediate: true,
      handler(value) {
        if (value == null) {
          this.onBehalfOf = this.$store.getters.customerNumber;
          this.setCustomer(this.$store.getters.customerId);
        } else {
          for (let i = 0; i < this.customers.length; i++) {
            if (this.customers[i].number == value) {
              this.setCustomer(this.customers[i].id);
              break;
            }
          }
        }
      },
    },
  },
  methods: {
    setAccountData(customer) {
      this.accountData = {
        salutation: customer.address.salutation || null,
        company: customer.address.company || null,
        first_name: customer.address.first_name || null,
        last_name: customer.address.last_name || null,
        corporate: customer.is_corporate,
        language: (customer.language || "DE").toLowerCase(),
        nationality: (customer.address.country || "CH").toLowerCase(),
        date_of_birth: customer.address.birthdate || null,
        email: customer.address.email || null,
        mobile: customer.address.mobile || null,
        address: {
          additional: customer.address.additional || null,
          street: customer.address.street || null,
          street_number: customer.address.street_number || null,
          zip_code: customer.address.zip_code || null,
          city: customer.address.city || null,
          country: customer.address.country || "CH",
        },
      };
    },
    getTomorrowString() {
      return this.$moment(new Date()).add(1, "days").format("YYYY-MM-DD");
    },
    getTodayString() {
      return this.$moment(new Date()).format("YYYY-MM-DD");
    },
    selectProduct(item, row) {
      this.productId = item.id;
      this.baseProductId = item.base_product_id;
      this.step = 2;
    },
    isWorkingDate(date) {
      // require at least 5 full days in advance
      let nextDate = this.addWorkingDays(new Date(), 5);
      let latestDate = this.$moment(new Date())
        .add(12, "months")
        .add(1, "days")
        .toDate();
      return (
        this.$moment(date).isAfter(nextDate) &&
        this.$moment(date).isBefore(latestDate) &&
        !this.isTeldasNoneWorkingDay(date)
      );
    },
    allowedDatesDelivery(date) {
      return (
        (this.transferData.transfer_date == null ||
          this.$moment(date).isAfter(
            this.$moment(this.transferData.transfer_date)
          ) ||
          this.$moment(date).isSame(
            this.$moment(this.transferData.transfer_date)
          )) &&
        this.isWorkingDate(date)
      );
    },
    allowedDatesTransfer(date) {
      return (
        (this.deliveryDate == null ||
          this.$moment(date).isBefore(this.$moment(this.deliveryDate)) ||
          this.$moment(date).isSame(this.$moment(this.deliveryDate))) &&
        this.isWorkingDate(date)
      );
    },
    allowedDateOfBirth(date) {
      let eighteenYearsAgo = this.$moment(new Date()).subtract(18, "years");
      return (
        this.$moment(date).isBefore(eighteenYearsAgo) ||
        this.$moment(date).isSame(eighteenYearsAgo, "day")
      );
    },
    allowedDatesPoa(date) {
      let now = this.$moment(new Date());
      return (
        this.$moment(date).isBefore(now) ||
        this.$moment(date).isSame(now, "day")
      );
    },
    providerFilter(item, queryText, itemText) {
      const searchText = queryText.toLowerCase();
      const text = item.caption.toLowerCase();
      return text.indexOf(searchText) > -1;
    },
    providerInput(value) {
      if (Object.prototype.toString.call(value) === "[object String]") {
        // to title case
        value = value
          .split(" ")
          .map((w) => w[0].toUpperCase() + w.substr(1).toLowerCase())
          .join(" ");
        this.providers = [{ id: null, caption: value }, ...this.providers];
        this.transferData.provider_account_name = value;
        this.transferData.provider_account_id = null;
        this.transferData.provider_account_search = value;
      } else if (value && value.id == null) {
        this.transferData.provider_account_name = value.caption;
        this.transferData.provider_account_id = null;
      } else if (value && value.id != null) {
        this.transferData.provider_account_name = null;
        this.transferData.provider_account_id = value.id;
      } else {
        this.transferData.provider_account_name = null;
        this.transferData.provider_account_id = null;
        this.transferData.provider_account_search = null;
        this.transferData.provider_account_value = null;
      }
    },
    getProductCatalog() {
      var that = this;
      this.loadingProductCatalog = true;
      this.$http
        .get("services/phone/product-catalog", { params: { base: "mobile" } })
        .then((response) => {
          that.productCatalog = response.data;
        })
        .catch((err) => {
          that.$store.commit("setSystemError", {
            msg: err.message,
            title: err.title,
          });
        })
        .finally(function () {
          that.loadingProductCatalog = false;
        });
    },
    getProviders() {
      var that = this;
      this.loadingProviders = true;
      this.$http
        .get("services/phone/telco-providers", {
          params: { base_product_id: this.baseProductId },
        })
        .then((response) => {
          that.providers = response.data;
        })
        .catch((err) => {
          that.$store.commit("setSystemError", {
            msg: err.message,
            title: err.title,
          });
        })
        .finally(function () {
          that.loadingProviders = false;
        });
    },
    order() {
      var that = this;
      this.loadingOrder = true;
      this.$http
        .post("services/phone/mobile-create", this.orderData)
        .then((response) => {
          that.$snotify.success(that.$i18n.t("notifyMsg"));
          if (this.onBehalfOrderType == "PAR")
            that.$router.push({
              name: "telephony-service-mobile-create",
            });
          else
            that.$router.push({
              name: "tasks",
              params: { selectedTaskId: response.data.id },
            });
        })
        .catch((error) => {
          //console.log(">>> error ", error.data);
          /** this will not work, as long erros will be displayed in hidden steps :-(
           *
          that.$refs.obs.setErrors(error.data);
          that.showErrors(that.$refs.obs.getUnresolvedErrors(error));
          */
          // show ALL errors as popup
          that.showErrors(error.data);
        })
        .finally(function () {
          that.loadingOrder = false;
        });
    },
    readCustomers(event) {
      var that = this;
      this.loadingCustomers = true;
      this.$http
        .get("customers", {
          params: { q: this.search, limit: 0 },
        })
        .then((response) => {
          that.customers = [
            {
              id: this.$store.getters.customerId,
              name: this.$i18n.t("for my self"),
              number: this.$store.getters.customerNumber,
            },
            ...response.data,
          ];
          that.onBehalfOf =
            event != null && event.number != null ? event.number : null;
        })
        .catch((error) => {
          that.$store.commit("setSystemError", {
            msg: error.message,
            title: error.title,
          });
        })
        .finally(function () {
          that.loadingCustomers = false;
        });
    },
    setCustomer(customerId) {
      var that = this;
      this.loadingCustomer = true;
      this.$http
        .get("customers/" + customerId)
        .then((response) => {
          that.setAccountData(response.data);
          that.deliveryAddress = { ...response.data.address };
          that.defaultDeliveryAddress = { ...that.deliveryAddress };
        })
        .catch((error) => {
          that.$store.commit("setSystemError", {
            msg: error.message,
            title: error.title,
          });
        })
        .finally(function () {
          that.loadingCustomer = false;
        });
    },
  },
  mounted() {
    this.getProductCatalog();
    if (this.$store.getters.isSalesPartner) this.readCustomers();
  },
};
</script>

<i18n>
{
  "en": {
    "account data": "Account data",
    "costs": "Costs",
    "monthly": "monthly",
    "unique": "unique",
    "options": "Options",
    "primary SIM card": "Primary SIM card",
    "type of primary SIM card": "type of primary SIM card",
    "primary SIM type": "Primary SIM type",
    "phys. SIM": "phys. SIM",
    "eSIM": "eSIM",
    "extra data SIM": "extra data SIM",
    "extra data eSIM": "extra data eSIM",
    "add phys. data SIM": "add phys. data SIM",
    "add data eSIM": "add data eSIM",
    "primary SIM card type": "Primary SIM card type",
    "primary SIM card wishnumber": "Primary SIM card wishnumber",
    "additional phys. SIM cards": "Additional phys. SIM cards",
    "additional eSIM cards": "Additional eSIM cards",
    "primary phone number": "primary phone number",
    "sim": "SIM",
    "esim": "eSIM",
    "optional wish number for your primary SIM card": "optional wish number for your primary SIM card",
    "delivery SIM card": "Delivery SIM card",
    "end customer account": "End customer account",
    "customer account": "Customer account",
    "end customer is corporate": "End customer is corporate",
    "customer is corporate": "Customer is corporate",
    "company": "Company",
    "mobile number": "Mobile number",
    "language": "Language",
    "identityMsg": "An identity verification is required for this order. It must be possible to send an authentication feature for the test. Please indicate below an email address.",
    "identityMsgWho": "An identity verification of the customer is required for this order. It must be possible to send an authentication feature directly to the customer for the test. Please indicate below an email address of the customer. Important: You are not allowed to do this test instead of the customer!",
    "identityMsg2": "For corporate customers, a natural person must be identified. Please enter their surnames and first names.",
    "continue": "Continue",
    "back": "Back",
    "order new mobile phone subscription": "Order new mobile phone subscription",
    "first name": "First name",
    "last name": "Last name",
    "identity check": "Identity check",
    "transfer number": "Transfer number",
    "permit": "Permit",
    "optional": "Optional",
    "continue": "Continue",
    "back": "Back",
    "order": "Order",
    "subscription": "Subscription",
    "delivery date": "Delivery date",
    "email": "Email",
    "email for order confirmation": "Notify email address",
    "product description": "Product description",
    "order note": "Own note for order confirmation",
    "Yes, I would like to port a number.": "Yes, I would like to port a number.",
    "No, I would not like to port a number.": "No, I would not like to port a number.",
    "phone number": "Phone number",
    "multi factor authentication type": "Multi factor authentication type",
    "authentication email address": "Authentication email address",
    "authentication SMS number": "Authentication SMS number",
    "authentication target": "Authentication target",
    "mfaTypeHint": "Multi factor authentication type",
    "date of birth": "Date of birth",
    "EMAIL": "Email",
    "SMS": "SMS",
    "PASSPORT": "Passport",
    "IDCARD": "ID Card",
    "OTHER": "Other",
    "nationality": "Nationality",
    "identity document type": "Identity document type",
    "identity document number": "Identity document number",
    "identity document": "Identity document",
    "identityDocumentHint": "Copy of identity document",
    "manual check": "Manual ID check on site",
    "is internal transfer": "is internal transfer",
    "handle transfer manually": "handle transfer manually",
    "use specific subscriber address": "use specific subscriber address",
    "transfer date": "Transfer date",
    "transfer time": "Transfer time",
    "transferDateHint": "leave empty to tranfer asap.",
    "POA document": "POA document",
    "poaDocumentHint": "completed and signed power of attorney form (see template link above)",
    "POA date": "POA date",
    "POA signed by": "POA signed by",
    "provider": "Provider",
    "transfer phone number": "Transfer phone number",
    "contract type": "Contract type",
    "MOBILE": "Mobile subscription",
    "MOBILEPREPAID": "Mobile prepaid",
    "transfer type": "Transfer type",
    "FULL": "Full transfer",
    "PARTIAL": "Partial transfer",
    "transfer priority": "Transfer priority",
    "NORMAL": "Take into account the duration of the contract",
    "FAST": "Do not take into account the duration of the contract",
    "is emergency porting": "is emergency porting",
    "subscriber company": "Subscriber company",
    "subscriber name": "Subscriber name",
    "subscriber address": "Subscriber address",
    "your subscription": "Your subscription",
    "user identify data": "User identify data",
    "number transfer data": "Number transfer data",
    "asap.": "asap.",
    "notifyMsg": "Your order has been successfully committed.",
    "POA document template": "POA document template",
    "end customer account data": "End customer account data",
    "customer account data": "Customer account data",
    "is corporate": "is corporate",
    "address": "Address",
    "lang_de": "German",
    "lang_en": "English",
    "lang_fr": "French",
    "lang_it": "Italian",
    "lang_es": "Spanish",
    "lang_pt": "Portuguese",
    "sales partner options": "Sales partner options",
    "for my self": "for my self",
    "on behalf of": "On behalf of",
    "order on behalf of your customer": "order on behalf of your customer",
    "on behalf order type": "Order type",
    "onBehalfOrderTypeHint": "The product is always created for your customer with you as a reference. For the ordering process, you can choose in whose name the order and communication will be carried out.",
    "order happens through you": "order happens through you",
    "order is placed by your customer": "order is placed by your customer",
    "add customer": "add own customer",
    "additional order data": "Additional order data",
    "additional SIM cards": "Additional data SIM cards",
    "subscription price": "Subscription price",
    "subscription setup fee": "Subscription setup fee",
    "confirm subscription terms": "confirm subscription terms",
    "delivery": "Delivery",
    "delivery address": "Delivery address"
  },
  "de": {
    "account data": "Kundenkonto Daten",
    "costs": "Kosten",
    "monthly": "monatlich",
    "unique": "einmalig",
    "options": "Optionen",
    "primary SIM card": "Primäre SIM Karte",
    "type of primary SIM card": "Typ der Primären SIM Karte",
    "primary SIM type": "Primärer SIM Typ",
    "phys. SIM": "phys. SIM",
    "eSIM": "eSIM",
    "extra data SIM": "extra Daten SIM",
    "extra data eSIM": "extra Daten eSIM",
    "add phys. data SIM": "phys. Daten SIM hinzufügen",
    "add data eSIM": "Daten eSIM hinzufügen",
    "primary SIM card type": "Primärer SIM Karten Typ",
    "primary SIM card wishnumber": "Primäre SIM Karten Wunschnummer",
    "additional phys. SIM cards": "Zusätzliche phys. SIM Karten",
    "additional eSIM cards": "Zusätzliche eSIM Karten",
    "primary phone number": "Primäre Rufnummer",
    "sim": "SIM",
    "esim": "eSIM",
    "optional wish number for your primary SIM card": "optionale Wunschnummer für die primären SIM Karte",
    "delivery SIM card": "Lieferung SIM Karte",
    "end customer account": "Endkundenkonto",
    "customer account": "Kundenkonto",
    "end customer is corporate": "Endkunde ist eine Firma",
    "customer is corporate": "Kunde ist eine Firma",
    "company": "Firma",
    "mobile number": "Mobil Nummer",
    "language": "Sprache",
    "identityMsg": "Für diese Bestellung ist eine Identitätsprüfung erforderlich. Für die Prüfung muss ein Authentisierungsmerkmal zugestellt werden können. Bitte geben Sie nachstehend Ihre E-Mailadresse an.",
    "identityMsgWho": "Für diese Bestellung ist eine Identitätsprüfung des Kunden erforderlich. Für den Test muss es möglich sein, ein Authentifizierungsmerkmal direkt an den Kunden zu senden. Bitte geben Sie unten eine E-Mail-Adresse des Kunden an. Wichtig: Sie dürfen diesen Test nicht anstelle des Kunden durchführen!",
    "identityMsg2": "Bei Firmenkunden muss eine natürliche Person identifiziert werden. Bitte geben Sie deren Namen und Vornamen an.",
    "continue": "Weiter",
    "back": "Zurück",
    "order new mobile phone subscription": "Neues Mobiltelefon Abonnement bestellen",
    "first name": "Vorname",
    "last name": "Nachname",
    "identity check": "Identitätsprüfung",
    "transfer number": "Rufnummer portieren",
    "permit": "Bestätigen",
    "optional": "Optional",
    "continue": "Weiter",
    "back": "Zurück",
    "order": "Bestellen",
    "subscription": "Abonnement",
    "delivery date": "Aufschaltdatum",
    "email": "Email",
    "email for order confirmation": "Email für Bestellbestätigung",
    "product description": "Produktbeschreibung",
    "order note": "Eigene Notiz für Bestellbestätigung",
    "Yes, I would like to port a number.": "Ja, ich möchte eine Rufnummer portieren.",
    "No, I would not like to port a number.": "Nein, ich möchte keine Rufnummer portieren.",
    "phone number": "Rufnummer",
    "multi factor authentication type": "Multi Faktor Authentisierungstyp",
    "authentication email address": "Authentisierungs Email Adresse",
    "authentication SMS number": "Authentisierungs SMS Nummer",
    "authentication target": "Authentisierungs Ziel",
    "mfaTypeHint": "Multi Faktor Authentisierungstyp",
    "date of birth": "Geburtsdatum",
    "EMAIL": "Email",
    "SMS": "SMS",
    "PASSPORT": "Reisepass",
    "IDCARD": "Identitätskarte",
    "OTHER": "Andere",
    "nationality": "Nationalität",
    "identity document type": "Ausweis Dokument Art",
    "identity document number": "Ausweis Dokument Nummer",
    "identity document": "Ausweis Dokument",
    "identityDocumentHint": "Kopie des Ausweis Dukuments",
    "manual check": "Manuelle Ausweisprüfung vor Ort",
    "is internal transfer": "ist ein interner Transfer",
    "handle transfer manually": "bearbeite Transfer manuell",
    "use specific subscriber address": "benutze abweichende Eigentümer Adresse",
    "transfer date": "Portierung Datum",
    "transfer time": "Portierung Zeit",
    "transferDateHint": "leer lassen für schnellstmögliche Portierung",
    "POA document": "Vollmachtsformular",
    "poaDocumentHint": "ausgefülltes und unterzeichnetes Vollmachtsformular (siehe Vorlagen Link oben)",
    "POA date": "Datum der Vollmacht",
    "POA signed by": "Vollmacht Unterschrieben von",
    "provider": "Anbieter",
    "transfer phone number": "Rufnummer zur Portierung",
    "contract type": "Vertragsart",
    "MOBILE": "Mobile Abo",
    "MOBILEPREPAID": "Mobile Prepaid",
    "transfer type": "Portierungsart",
    "FULL": "Vollportierung",
    "PARTIAL": "Teilportierung",
    "transfer priority": "Portierung Priorität",
    "NORMAL": "Vertragslaufzeit berücksichtigen",
    "FAST": "Vertragslaufzeit nicht berücksichtigen",
    "is emergency porting": "ist eine Notportierung",
    "subscriber company": "Teilnehmer Firma",
    "subscriber name": "Teilnehmer Name",
    "subscriber address": "Teilnehmer Adresse",
    "your subscription": "Ihr Abonnement",
    "user identify data": "Daten zur Teilnehmer Identifizierung",
    "number transfer data": "Daten zur Rufnummer Portierung",
    "asap.": "schnellstmöglich",
    "notifyMsg": "Ihre Bestellung wurde erfolgreich übermittelt.",
    "POA document template": "Portierungvollmacht (POA) Vorlage",
    "end customer account data": "Endkundenkonto Daten",
    "customer account data": "Kundenkonto Daten",
    "is corporate": "ist Firma",
    "address": "Adresse",
    "lang_de": "Deutsch",
    "lang_en": "Englisch",
    "lang_fr": "Französisch",
    "lang_it": "Italienisch",
    "lang_es": "Spanisch",
    "lang_pt": "Portugiesisch",
    "sales partner options": "Vertriebspartner Optionen",
    "for my self": "für mich selbst",
    "on behalf of": "Im Auftrag von",
    "order on behalf of your customer": "Bestellung im Auftrag Ihres Kunden",
    "on behalf order type": "Bestelltyp",
    "onBehalfOrderTypeHint": "Das Produkt wird immer für Ihren Kunden mit Ihnen als Referenz angelegt. Für den Bestellablauf können Sie wählen, in wessen Namen die Bestellung und die Kommunikation ausgeführt wird.",
    "order happens through you": "Bestellung erfolgt über Sie",
    "order is placed by your customer": "Bestellung erfolgt über Ihren Kunden",
    "add customer": "eigenen Kunden hinzufügen",
    "additional order data": "Zusätzliche Bestelldaten",
    "additional SIM cards": "Zusätzliche Daten SIM Karten",
    "subscription price": "Abonnement Preis",
    "subscription setup fee": "Abonnement Installationsgebühr",
    "confirm subscription terms": "Abonnementbedingungen bestätigen",
    "delivery": "Lieferung",
    "delivery address": "Lieferadresse"
  }
}
</i18n>

<style scoped>
table > tbody > tr > td:nth-child(odd) {
  width: 40%;
}
table > tbody > tr > td:nth-child(even) {
  font-style: italic;
  width: 60%;
}
</style>
